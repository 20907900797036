import { IInitialWindow } from '@/src/core/types/interfaces';

export enum FEATURES {
  FORWARDER_SELECTION = 'enableForwarderSelection',
  DOCUMENT_DOWNLOAD = 'enableDocumentDownload',
  RECOMMENDATIONS = 'enableRecommendations',
  SATAIR_QUOTES = 'enableSatairQuotes',
  GET_ACCESS = 'enableGetAccess',
  FINANCE_SECTION = 'enableFinanceSection',
  EXTENDED_UPDATES = 'enableExtendedUpdates',
  EUS_HANDLING = 'enableEusHandling',
  MAINTENANCE_MODE = 'enableMaintenanceMode',
  CASH_IN_ADVANCE = 'enableCashInAdvance',
  ONE_PORTAL = 'enableOnePortal',
  ONE_PORTAL_WAVE_0 = 'enableOnePortalWave0',
  ONE_PORTAL_WAVE_1 = 'enableOnePortalWave1',
  ONE_PORTAL_WAVE_2 = 'enableOnePortalWave2',
  LEASE_SERVICE_ORDERS = 'enableLeaseServiceOrders',
  REPAIR_EXCHANGE_SERVICE_ORDERS = 'enableRepairExchangeServiceOrders',
  PLANNING = 'enablePlanning',
  HEAVY_MAINTENANCE = 'enableHeavyMaintenance',
  FINANCE_AIRBUS = 'enableFinanceAirbus',
  AIRBUS_TOOL_SERVICE_QUOTATION = 'enableAirbusToolServiceQuotation',
  AIRBUS_PROP_PARTS_SERVICE_QUOTATION = 'enableAirbusPropPartsServiceQuotation',
  NEW_LOGIN_DESIGN = 'enableNewLoginDesign',
  INACTIVITY_LOGOUT_TIMER = 'enableInactivityLogoutTimer',
  ENABLE_APIM = 'enableApim',
}

export const hasFeature = (feature: FEATURES) => {
  const features = (window as unknown as IInitialWindow).features ?? {};

  return Boolean(features[feature]);
};

export type FeatureMap = {
  [key in FEATURES]: boolean;
};
