import { UmbracoApi } from '@/src/core/api';
import router from '@/src/core/router';
import { AdobeLaunchTracking } from '@/src/core/services/adobelaunchtracking';
import { getEnv, isDevelopment } from '@/src/core/services/environment';
import { mapValidationErrorToNotification } from '@/src/core/services/notifications';
import { InteractionEventBus } from '@/src/core/utils/interaction-event-bus';
import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import { useAuthenticationStore } from '../stores/authentication';
import { useModalStore } from '../stores/modal';
import { useNotificationsStore } from '../stores/notifications';
import { SubContentEventBus } from '../utils/sub-content-event-bus';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { HttpResponse } from '@/src/core/apim';

const LOGGED_OUT_MESSAGE = 'User not logged in.';

/* tslint:disable */

export type UmbracoResponseModel<T extends { [key: string]: any }> = ReturnType<
  typeof runModules<T>
>;

export enum ReqQueueTypes {
  Default,
  FirstOnly,
  LastResponse,
}

interface QueuePromise {
  resolve: (value?: any) => void;
  reject: (error: any) => void;
}

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

// to register call as AJAX in MVC
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// refresh authToken before making a request
// if authToken has expired
axios.interceptors.request.use(
  async (config) => {
    if (
      authenticationStore.isAccessTokenExpired() &&
      authenticationStore.isAuthenticated &&
      config.url !== UmbracoApi.getRefreshToken &&
      config.url !== UmbracoApi.apimLogin
    ) {
      if (!isAuthTokenRefreshing) {
        isAuthTokenRefreshing = true;
        authenticationStore
          .refreshAccessToken()
          .then(() => {
            isAuthTokenRefreshing = false;
            processExpiredTokenQueue(null);
          })
          .catch((error) => {
            processExpiredTokenQueue(error);
            throw error;
          });
      }
      return new Promise((resolve, reject) => {
        expiredAuthTokenQueue.push({
          resolve: () => {
            resolve(config);
          },
          reject: (error) => {
            reject(error);
          },
        });
      });
    }

    return config;
  },
  (error) => Promise.reject(error),
);

// When we receive 302 to a foreign origin, we need to have CORS setup.
// In order to avoid that, 302s are returned as 200 with a location header,
// which we automatically redirect to, if the redirect is "safe"
// where "safe" means either same origin or matches ABW

// refresh authToken after receiving a 401 response
// to handle an edgecase

let refreshTokenPromise: Promise<void> | null = null;
const clearRefreshTokenPromise = () => (refreshTokenPromise = null);
const redirectUrlIsSafe = (url: string) => {
  const { origin: redirectOrigin } = new URL(url);
  const { origin: redirectUrlSso } = new URL(getEnv('VUE_APP_SSO_AUTH_URI'));
  const { origin: currentOrigin } = new URL(location.href);

  return [redirectUrlSso, currentOrigin].includes(redirectOrigin);
};

axios.interceptors.response.use(
  (response) => {
    const redirectUrl = response.headers.location;

    if (redirectUrl) {
      try {
        if (redirectUrlIsSafe(redirectUrl)) {
          window.location.href = redirectUrl;
        }
      } catch (e) {
        console.error(e);
      }
    }

    return response;
  },
  async (error) => {
    const config: CustomAxiosRequestConfig = error.config;

    if (authenticationStore.isAuthenticated && error.response.status === 401) {
      if (config._retry) {
        authenticationStore.doLogout();
        return;
      } else {
        config._retry = true;

        if (!refreshTokenPromise) {
          refreshTokenPromise = authenticationStore
            .refreshAccessToken()
            .finally(clearRefreshTokenPromise);
        }

        await refreshTokenPromise;

        return axios(config);
      }
    }

    throw error;
  },
);

/**
 * ReqQue is used to specify how we handle concurrent requests
 *
 * When we have a second request, to the same @Category before
 * the first request has settled, the response for the second
 * request will be as follows:
 * @Type - ReqQueueTypes:
 *  - Default: undefined (cancelled)
 *  - FirstOnly: undefined
 *  - LastResponse: null | last saved value
 *  - Batch: wait for response from first request
 */
export class ReqQueue {
  constructor(
    public Type: ReqQueueTypes = ReqQueueTypes.Default,
    public Category?: string,
    public Id?: string,
    public Uid?: string,
    public Cancel: boolean = false,
    public CancelToken: CancelTokenSource = axios.CancelToken.source(),
    public IsLast: boolean = true,
  ) {}
}

const authenticationStore = useAuthenticationStore();
let axiosQueue: ReqQueue[] = [];
let expiredAuthTokenQueue: QueuePromise[] = [];
let isAuthTokenRefreshing = false;

const processExpiredTokenQueue = (error: any): void => {
  expiredAuthTokenQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  expiredAuthTokenQueue = [];
};

export function Req<T extends { [key: string]: any } = any>(
  settings: AxiosRequestConfig,
  queueSettings: ReqQueue = new ReqQueue(),
  apimHandler?: (settings: AxiosRequestConfig) => Promise<HttpResponse<T>>,
): Promise<UmbracoResponseModel<T>> {
  runPreModules(settings);

  handlePreQueue(queueSettings);

  InteractionEventBus.$emit('request');

  if (queueSettings.Cancel) {
    return new Promise((resolve) => {
      resolve({ IsSuccess: false, Data: null });
    });
  }

  settings.cancelToken = queueSettings.CancelToken.token;

  const useApim = typeof apimHandler !== 'undefined' && hasFeature(FEATURES.ENABLE_APIM);
  const prom = useApim
    ? apimHandler(settings)
    : axios({
        ...settings,
        withCredentials: isDevelopment(),
      });

  setTimeout(async () => {
    const apimDebug = getEnv('VUE_APP_APIM_DEBUG') === 'true';

    if (!useApim || !apimDebug) {
      return;
    }

    const [apim, umbraco] = await Promise.all([
      prom,
      axios({
        ...settings,
        withCredentials: isDevelopment(),
      }),
    ]);

    console.groupCollapsed(settings.url);
    console.log('APIM', apim.data);
    console.log('.NET', umbraco.data);
    console.groupEnd();
  }, 0);

  return prom
    .then((resp) => runModules<T>(resp, queueSettings))
    .catch((error) => runModules<T>(error.response, queueSettings));
}

export function CancelReqCategory(category: string) {
  const categoryQ = axiosQueue.filter((q) => {
    return q.Category === category;
  });

  categoryQ.forEach((item) => {
    item.CancelToken.cancel();
  });
}

function handlePreQueue(queueSettings: ReqQueue): ReqQueue {
  if (!queueSettings.Category) {
    return queueSettings;
  }

  queueSettings.Uid = Guid.newGuid();

  let categoryQ = axiosQueue.filter((q) => {
    return q.Category === queueSettings.Category;
  });

  if (queueSettings.Id) {
    categoryQ = categoryQ.filter((q) => {
      return q.Id === queueSettings.Id;
    });
  }
  if (queueSettings.Type === ReqQueueTypes.FirstOnly) {
    if (categoryQ.length > 0) {
      // cancel
      queueSettings.Cancel = true;
      return queueSettings;
    }
  } else if (queueSettings.Type === ReqQueueTypes.LastResponse) {
    axiosQueue.forEach((item) => {
      item.IsLast = false;
    });
  } else {
    categoryQ.forEach((item) => {
      item.CancelToken.cancel();
    });
  }

  axiosQueue.push(queueSettings);

  return queueSettings;
}

function runModules<T extends { [key: string]: any }>(
  resp: AxiosResponse<any> | HttpResponse<T>,
  queueSettings?: ReqQueue,
) {
  const authenticationStore = useAuthenticationStore();
  const notificationsStore = useNotificationsStore();
  const modalStore = useModalStore();

  if (queueSettings) {
    // remove queue item
    axiosQueue = axiosQueue.filter((q) => {
      return q.Uid !== queueSettings.Uid;
    });
  }

  if (resp?.data) {
    const { data } = resp;
    const validationErrors = mapValidationErrorToNotification(data.Data?.errors);
    const notifications = [...(data.Notification ?? []), ...validationErrors];

    if (notifications) {
      for (const notificationItem of notifications) {
        if (notificationItem.CloseDrawer) {
          setTimeout(() => {
            SubContentEventBus.$emit('closeSubContent');
          }, 2500);
        }
        notificationsStore.addNotification({ notificationItem });
      }
    }

    if (data.Authentication) {
      authenticationStore.setTokens({
        globalId: data.Authentication.GlobalId,
        isAdmin: data.Authentication.IsAdmin,
        isReadOnly: data.Authentication.IsReadOnly,
        isFinance: data.Authentication.IsFinance,
        betaCustomer: data.Authentication.BetaCustomer,
        readOnlyAccount: data.Authentication.ReadOnlyAccount,
        isFirstTime: data.Authentication.IsFirstTime,
        rememberMe: data.Authentication.RememberMe,
        hasAcceptedTou: data.Authentication.HasAcceptedTou,
        hasAcceptedReleaseNotes: data.Authentication.HasAcceptedReleaseNotes,
        userUnitType: data.Authentication.UserUnitType,
      });
    }
    // Handle auth and modal in our router
    if (data.CustomActions) {
      for (const entry of data.CustomActions) {
        if (entry.ActionType === 'Login') {
          authenticationStore.doLogout(true);
          modalStore.showModal({
            modalComponent: 'ModalLogin',
            single: true,
          });
        } else if (entry.ActionType === 'VueRedirect') {
          router.push({ name: entry.VueRedirectName });
        } else if (entry.ActionType === 'TriggerModal') {
          modalStore.showModal({
            modalComponent: entry.ModalData.Name,
            params: entry.ModalData.Data,
            first: true,
          });
        }
      }
    }

    if (data?.Data === LOGGED_OUT_MESSAGE) {
      authenticationStore.doLogout(true);
      modalStore.showModal({
        modalComponent: 'ModalLogin',
        single: true,
      });
    }

    const titlekey = 'title';
    if (resp.headers[titlekey]) {
      document.title = resp.headers[titlekey];
    }

    const trackkey = 'track';
    if (resp.headers[trackkey]) {
      AdobeLaunchTracking.applyTrackingHeader(resp.headers[trackkey]);
    }

    if (
      resp.status !== 200 ||
      (data.Error && data.Error.FatalError) ||
      (queueSettings && !queueSettings.IsLast)
    ) {
      return { IsSuccess: false, Data: null } as const;
    }
    return { IsSuccess: true, Data: resp.data.Data as T['Data'] } as const;
  }

  if (resp && resp.status === 200) {
    return { IsSuccess: true, Data: null } as const;
  }

  return { IsSuccess: false, Data: null, Error: resp?.data?.Error } as const;
}

function runPreModules(settings: AxiosRequestConfig) {
  const authenticationStore = useAuthenticationStore();
  if (authenticationStore.isAuthenticated) {
    settings.headers = Object.assign(
      settings.headers || {},
      authenticationStore.getAuthenticationTokenHeaders,
    );
  } else {
    settings.headers = Object.assign(
      settings.headers || {},
      authenticationStore.getUnauthorizedHeader,
    );
  }
}

class Guid {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
}
