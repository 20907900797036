import { getEnv, isDevelopment } from '@/src/core/services/environment';
import { Api } from '@/src/core/apim/spec';
import { FEATURES, hasFeature } from '@/src/core/services/features';
import { UmbracoApi } from '@/src/core/api/umbracoApi';
import { Req } from '@/src/core/services/requester';
import Cookies from 'js-cookie';

const apimUrl = getEnv('VUE_APP_HOST_APIM') ?? '';

const api = new Api({
  baseUrl: apimUrl,
  async customFetch(url, options) {
    if (options) {
      options.headers = {
        'X-Requested-With': 'XMLHttpRequest', // Umbraco can differentiate between fetch request or browser request
        env: isDevelopment() ? 'localhost' : undefined!,
        ...(options.headers || {}),
      };
      options.credentials = 'include';
    }

    return fetch(url, options);
  },
});

export const apim = api.baseSiteId;
export const abortRequest = api.abortRequest;
