import { upperFirst } from '@/src/core/utils/string';

/**
 * Object Values
 * The Object.values provides the correct type, so we just have this function for consistency for the `keys` and `entries` functions.
 * @see keys
 * @see entries
 * @typed
 */
export const oValues = Object.values as <T extends object>(o: T) => Array<T[keyof T]>;

/**
 * Object Keys
 * The Object.keys method doesn't provide the correct type for the key, hence the creation of this function.
 * @typed
 */
export const oKeys = Object.keys as <T extends object>(o: T) => Array<keyof T>;

/**
 * Object Entries
 * The Object.entries method doesn't provide the correct type for the key, hence the creation of this function.
 * @typed
 */
export const oEntries = Object.entries as <T extends object>(o: T) => Array<[keyof T, T[keyof T]]>;

type Primitive = string | number | boolean | null | undefined;

export type ConvertToPascalCase<T> = T extends Primitive | Function
  ? T
  : T extends Array<infer U>
  ? Array<ConvertToPascalCase<U>>
  : T extends object
  ? {
      [K in keyof T as K extends string ? Capitalize<K> : K]: ConvertToPascalCase<T[K]>;
    }
  : T;

/**
 * This function clones an object and converts it's keys from CamelCase to PascalCase.
 * @param obj any object
 * @returns clone of object with keys to PascalCase
 * @example `
 * objectKeysToPascalCaseRecursive(obj);
 * `
 */
export const objectKeysToPascalCaseRecursive = <T>(obj: T): ConvertToPascalCase<T> => {
  if (obj === null || typeof obj !== 'object') {
    return obj as ConvertToPascalCase<T>;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => objectKeysToPascalCaseRecursive(item)) as ConvertToPascalCase<T>;
  }

  const result: any = {};

  for (const [key, value] of Object.entries(obj)) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const pascalKey = upperFirst(key);
      result[pascalKey] = objectKeysToPascalCaseRecursive(value);
    }
  }

  return result as ConvertToPascalCase<T>;
};
