/* tslint:disable */

import { AirbusExtendedOrderDetails, BasePrice, OrderStatus } from '@/src/core/types/interfaces';

// ..\Satair.Core\Models\Domain\AccountStatementRequest.cs
export interface AccountStatementRequest {
  UnitId: string;
  FromDate: string;
  ToDate: string;
  FileType: FileTypes;
  OpenItems: boolean;
  OverdueItems: boolean;
}

// ..\Satair.Core\Models\Domain\AccountStatementRequest.cs
export enum FileTypes {
  excel = 'excel',
  pdf = 'pdf',
}

// ..\Satair.Core\Models\Domain\Address.cs
export interface ShipToAddresses {
  Addresses: Address[];
}

// ..\Satair.Core\Models\Domain\Address.cs
export interface Address {
  Country: Country;
  CompanyName: string;
  DefaultAddress: boolean;
  Email: string;
  FirstName: string;
  FormattedAddress: string;
  Id: string;
  LastName: string;
  Line1: string;
  Line2: string;
  PostalCode: string;
  RegionCode: string;
  ShippingAddress: boolean;
  Title: string;
  TitleCode: string;
  Town: string;
  VisibleInAddressBook: boolean;
  ShipToCode: string;
  Street4: string;
  BillingAddress: boolean;
  VatId: string;
  Cellphone: string;
  DefaultBillingAddress: boolean;
  DefaultPayerAddress: boolean;
  DefaultShippingAddress: boolean;
  District: string;
  OpeningHours: string;
  PayerAddress: boolean;
  Phone: string;
  SapCustomerId: string;
  SoldtoAddress: boolean;
  RestrictedAddress: boolean;
  AddressTypeFriendlyName: string;
  TimeZone: string;
  PostalCodeTown: string;
  RestrictedMasterShopIds: string[];
  ExportBlocked: boolean;
  EusRequired: boolean;
}

// ..\Satair.Core\Models\Domain\Admin.cs
export interface Admin {
  Users: User[];
}

// ..\Satair.Core\Models\Domain\B2BUnit.cs
export interface B2BUnit {
  AccountGroup: string;
  Addresses: Address[];
  Name: string;
  Uid: string;
  BillingAddress: Address;
  vatID: string;
  ReadOnlyAccount: boolean;
  ExportBlocked: boolean;
  EusRequired: boolean;
  RequiresCashInAdvance: boolean;
  ForwarderInformationRequired: boolean;
  OwningSystem: string;
  RedB2BUnit?: B2BUnit;
  BlueB2BUnit?: B2BUnit;
  BlueAddresses: Address[];
  RedAddresses: Address[];
  customerIdentificationCode?: string;
  DirectorAssigned: string;
  District: CodeValue;
  b2BUnitSalesOrganization: CodeValue;
  CompanyCategory: CodeValue;
}

// ..\Satair.Core\Models\Domain\BillingDocumentInfo.cs
export interface BillingDocumentInfo {
  BillingDocumentInfos: BillingDocuments[];
  DocumentsCount: number;
}

// ..\Satair.Core\Models\Domain\BillingDocumentInfo.cs
export interface BillingDocuments {
  OrderNumber: string;
  FormattedOrderNumber: string;
  DocumentNumber: string;
  FormattedDocumentNumber: string;
  DocumentType: string;
  DocumentTypeDescription: string;
  CreateDate: string;
  PoNumber: string;
  NetAmount: ProductVolumePrice;
  VatAmount: ProductVolumePrice;
  TotalAmount: ProductVolumePrice;
  DocumentUrl: string;
}

// ..\Satair.Core\Models\Domain\Carrier.cs
export interface Carrier extends CodeValue {
  Preferred: boolean;
  ServiceLevelRequired: boolean;
  LastUsedDate?: string;
}

// ..\Satair.Core\Models\Domain\CarrierSelection.cs
export interface CarrierSelection {
  Carriers: Carrier[];
  PreferedCarriers: Carrier[];
}

// ..\Satair.Core\Models\Domain\Cart.cs
export interface Cart extends CodeValue {
  Guid: string;
  PurchaseOrderNumber: string;
  RemovedCartEntries: RemovedCartEntry[];
  ProjectID: string;
  Description: string;
  PriorityInfo: PriorityInfo;
  Priority: string;
  Entries: CartEntry[];
  SellerGroup: SellerGroup[];
  HasMarketplaceSellers: boolean;
  RawEntries: CartEntry[];
  SubTotal: ProductPrice;
  ShowTotal: boolean;
  TotalPrice: ProductPrice;
  Carrier: Carrier;
  CarrierInfo: string;
  DeliveryAddress: Address;
  Unit: B2BUnit;
  NamedDeliveryDate?: string;
  RequestedDeliveryDate?: string;
  SaveTime: string;
  ModifiedTime: string;
  CreationTime: string;
  SavedBy: string;
  IsSaved: boolean;
  CartChangedExternally: boolean;
  IsFull: boolean;
  HasEntriesWithMultipleWarehouse: boolean;
  CarrierInstructions: string;
  HasMinOrderLineValue: boolean;
  UnknownDeliveryDate: boolean;
  HasAddressRestrictedEntries: boolean;
}

// ..\Satair.Core\Models\Domain\CartAvailability.cs
export interface CartAvailability {
  AvailabilityDate: string;
  Quantity: number;
  IsAsmHidden: boolean;
  UnknownDeliveryDate: boolean;
}

// ..\Satair.Core\Models\Domain\CartEntry.cs
export interface CartEntry {
  AirbusRemarks?: string;
  EntryNumber: number;
  Id: string;
  WarehouseGroupEntryNumber: number;
  PriceAvailable: boolean;
  EligibleForVolumePrices: boolean;
  Product: Product;
  OfferInfo: OfferInfo;
  MasterShop: MarketplaceMasterShop;
  Quantity?: number;
  RemainingQuantity?: number;
  OrderedQuantity?: number;
  StockLevel?: number;
  LaterDeliveryQty?: number;
  LeadTimeDays?: number;
  TotalPrice: ProductPrice;
  StockStatus: StockStatus;
  StockText: string;
  BasePrice: ProductPrice;
  AvailabilityInfos: CartAvailability[];
  AvailabilityList: ProductAvailable[];
  AvailabilityIndicator: string;
  PriorityInfo: PriorityInfo;
  Warehouse: CodeValue;
  WarehouseQuantity?: number;
  ConfirmedDeliveryInfo: ConfirmedDeliveryInfo[];
  CartEntryRoundedFromSap: boolean;
  IsSatairProduct: boolean;
  ChangeReasons: ChangeReason[];
  HasMultipleWarehouse: boolean;
  EnableCustomizeWarehouse: boolean;
  DefaultLocation: CodeValue;
  SingleCustomLocation: boolean;
  PriceUnit: string;
  SlowMoverPriceAvailable: boolean;
  SalesUnit: string;
  Fees: FeeItem[];
  HazmatFee: FeeItem;
  Price: ProductPrice;
  ShowPrice: boolean;
  ShowOrderButton: boolean;
  RuleStatus: string;
  State: string;
  HasMinOrderLineValue: boolean;
  HasMinOrderValue: boolean;
  ContainsUnknownMaterial: boolean;
  UnknownCageCode: string;
  UnknownName: string;
  UnknownPartNumber: string;
  UnknownDeliveryDate: boolean;
  ExpirationDate: string;
  MiraklQuoteResponseLineId: string;
  MiraklQuoteResponseId: string;
  ShippingPrice: ProductVolumePrice;
  TotalPriceWithShipping: ProductVolumePrice;
  InitialQuoteRequest: boolean;
  OfferQuoteResponseAccepted: boolean;
  OfferQuoteResponseReady: boolean;
  OrderableStatus: string;
  Offer: ProductOffer;
  OfferedTotalPrice: ProductPrice;
  OfferedBasePrice: ProductPrice;
  Orderable: boolean;
  EntryIsExpired: boolean;
  EntryIsCancelled: boolean;
  AirbusInterchangeabilityInfo: AirbusInterchangeabilityInfo;
}

// ..\Satair.Core\Models\Domain\CartEntry.cs
export enum StockStatus {
  Ready = 'Ready',
  Partly = 'Partly',
  OutOfStock = 'OutOfStock',
}

// ..\Satair.Core\Models\Domain\CartEntryDetails.cs
export interface CartEntryDetails {
  HasCartQuantity: boolean;
  WarehouseCode: string;
  WarehouseName: string;
  WarehouseQuantity?: number;
  WarehouseInStock?: boolean;
  EntryNumber?: number;
  Unit: string;
  Quantity?: number;
}

// ..\Satair.Core\Models\Domain\CartModification.cs
export interface CartModification {
  CartModifications: CartModificationValue[];
  FailCount: number;
  FailIndicator: FailIndicators;
  InActiveOfferRemoved: boolean;
  ModificationName: string;
}

// ..\Satair.Core\Models\Domain\CartModification.cs
export interface CartModificationValue {
  Quantity: string;
  QuantityAdded: number;
  Entry: CartModificationProduct[];
  StatusCode: string;
  StatusMessage: string;
  SuccessAdded: boolean;
}

// ..\Satair.Core\Models\Domain\CartModification.cs
export interface CartModificationProduct {
  EntryNumber: string;
  Product: Product;
  CartEntryRoundedFromSap: boolean;
}

// ..\Satair.Core\Models\Domain\CartModification.cs
export enum FailIndicators {
  Full = 'Full',
  Partial = 'Partial',
  None = 'None',
  IncorrectCurrency = 'IncorrectCurrency',
  MultipleOffers = 'MultipleOffers',
  MaxAllowedQuantityReached = 'MaxAllowedQuantityReached',
  InActiveOfferRemoved = 'InActiveOfferRemoved',
}

// ..\Satair.Core\Models\Domain\ChangeReason.cs
export interface ChangeReason {
  NewValue: string;
  OldValue: string;
  Reason: string;
}

// ..\Satair.Core\Models\Domain\CodeDescription.cs
export interface CodeDescription {
  Code: string;
  Description: string;
}

// ..\Satair.Core\Models\Domain\CodeValue.cs
export interface CodeValue {
  Code: string;
  Name: string;
}

// ..\Satair.Core\Models\Domain\ConfirmedDeliveryInfo.cs
export interface ConfirmedDeliveryInfo {
  ConfirmedDeliveryDate: string;
  Quantity: number;
}

// ..\Satair.Core\Models\Domain\Consignment.cs
export interface Consignment {
  Status: string;
  StatusDisplay: string;
  ShippingDate?: string;
  StatusDate: string;
  CarrierDetails: Carrier;
  BlueForwarderString: string;
  Entries: ConsignmentEntry[];
  TrackingId: string;
  ContainersInThisShipment: ConsignmentContainer[];
  Documents: Document[];
  Code: string;
}

// ..\Satair.Core\Models\Domain\Consignment.cs
export interface ConsignmentEntry {
  BatchNumber: string;
  Quantity: number;
  OrderEntry: CartEntry;
  ContainersWithThisEntry: ConsignmentContainer[];
}

// ..\Satair.Core\Models\Domain\Container.cs
export interface ConsignmentContainer {
  ContainerId: string;
  Height: string;
  Length: string;
  PackageType: string;
  Weight: string;
  Width: string;
  DimensionsUnit: string;
  WeightUnit: string;
  DimenstionsString: string;
}

// ..\Satair.Core\Models\Domain\CostCenters.cs
export interface OrderCostCenters {
  CostCenters: Costcenter[];
}

// ..\Satair.Core\Models\Domain\CostCenters.cs
export interface Costcenter extends CodeValue {
  Active: string;
  Unit: CostcenterUnit;
}

// ..\Satair.Core\Models\Domain\CostCenters.cs
export interface CostcenterUnit {
  Addresses: Address[];
}

// ..\Satair.Core\Models\Domain\CostCenters.cs
export interface Country {
  IsoCode: string;
  Name: string;
}

// ..\Satair.Core\Models\Domain\DisplayListItem.cs
export interface DisplayListItem {
  Name: string;
  DisplayType: string;
  Value: string[];
  CustomValue?: any;
}

// ..\Satair.Core\Models\Domain\DisplayListItemProduct.cs
export interface DisplayListItemProduct {
  Name: string;
  Value: any;
  TestTag: string;
}

// ..\Satair.Core\Models\Domain\Document.cs
export interface Document {
  Code: string;
  FileName: string;
  Type: string;
  ConsignmentCode: string;
  DocumentUrl: string;
}

// ..\Satair.Core\Models\Domain\DocumentDownload.cs
export interface DocumentDownload {
  ContentType: string;
  Bytes: string[];
  FileName: string;
}

// ..\Satair.Core\Models\Domain\DocumentsRequest.cs
export interface DocumentsRequest {
  DocCat: string;
  DocNum: string;
  FormattedDocNum: string;
}

// ..\Satair.Core\Models\Domain\Facets.cs
export interface Facets {
  MultiSelect?: boolean;
  Name: string;
  DisplayName: string;
  Values: FacetValues[];
}

interface QueryValue {
  Value: string;
}

interface FacetQueryValue {
  Query: QueryValue;
  Url: string;
}

// ..\Satair.Core\Models\Domain\Facets.cs

export interface FacetValues {
  Count: number;
  Name: string;
  SubLabel: string;
  Selected?: boolean;
  Query: FacetQueryValue;
  Code: string;
}

// ..\Satair.Core\Models\Domain\Fee.cs
export interface Fee {
  Amount: number;
  Label: string;
  Type: string;
}

// ..\Satair.Core\Models\Domain\FeeItem.cs
export interface FeeItem {
  Amount: number;
  Label: string;
  Type: string;
  Price: ProductPrice;
}

// ..\Satair.Core\Models\Domain\Forwarder.cs
export interface Forwarder {
  AdditionalInfo: string;
  Carrier: Carrier;
  ForwarderAccountNumber: string;
  ForwarderName: string;
  ServiceLevelAgreements: string;
}

// ..\Satair.Core\Models\Domain\Interchangeable.cs
export interface InterchangeableOMock {
  Product: Product;
  TwoWay: boolean;
}

// ..\Satair.Core\Models\Domain\InvoiceItemsList.cs
export interface InvoiceItemsList {
  InvoiceItems: InvoiceItem[];
}

// ..\Satair.Core\Models\Domain\InvoiceItemsList.cs
export interface InvoiceItem {
  Amount: ProductVolumePrice;
  DocumentDate: string;
  DueDate: string;
  InvoiceNumber: string;
  FormattedInvoiceNumber: string;
  Reference: string;
  Transaction: string;
}

// ..\Satair.Core\Models\Domain\IProductFacet.cs
export interface IProductFacet {
  Facets: Facets[];
}

// ..\Satair.Core\Models\Domain\ListImport.cs
export interface ListImport {
  Errors: string[];
  ImportStatus: ListImportStatus;
  ProductList: Cart;
  ListCode: string;
  CorrelationId: string;
  ProductCount: number;
}

// ..\Satair.Core\Models\Domain\ListImport.cs
export enum ListImportStatus {
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
}

// ..\Satair.Core\Models\Domain\ListType.cs
export enum ListType {
  List = 'List',
  QuoteDraft = 'QuoteDraft',
}

// ..\Satair.Core\Models\Domain\LoginCredentials.cs
export interface LoginCredentials {
  AccessToken: string;
  ExpiresIn: number;
  TokenType: string;
  RefreshToken: string;
  GlobalId: string;
  Error: string;
  ErrorDescription: string;
}

// ..\Satair.Core\Models\Domain\MarketplaceMasterShop.cs
export interface MarketplaceMasterShop {
  Address: Address;
  Banner: string;
  CompanyDunsNumber: string;
  CompanyOfficeDays: string[];
  CompanyPublicHolidays: string;
  TaxIdNumber: string;
  CountryShippedFrom: string;
  Description: string;
  ShortDescription: string;
  Id: string;
  Logo: string;
  Name: string;
  PaymentTerms: string;
  Website: string;
  CompanyType: string[];
  Shops: MarketplaceShop[];
  BannerCdnUrl: string;
  LogoCdnUrl: string;
  ReachCompliance: string;
  RohsCompliance: string;
  SatairClassification: string;
  SatairClassificationDescription: string;
  SatairClassificationExpirationDate?: string;
  LeadShopId: string;
  QualitySelfAssesmentUrl: string;
  Documents: MarketplaceShopDocument[];
  TermsAndPolicies: MarketplaceShopDocument[];
  MinimumOrderLineValueUsd: number;
  MinimumOrderValueUsd: number;
  ReturnPolicy: string;
  PriorityHandling: CodeValue[];
  Priorities: string[];
  PriorityFee: PriorityFee;
  DisplayAddress: string;
  RegistrationNumber: string;
  ShowTermsSection: boolean;
  ShowCompanyDetailsSection: boolean;
}

// ..\Satair.Core\Models\Domain\MarketplaceMasterShop.cs
export interface MarketplaceShop {
  Id: string;
  Name: string;
  LeadShop: boolean;
  Authorisations: ShopAuthorisation[];
  LocationDisplayName: string;
  OtherAuthorisations: boolean;
  OtherAuthorisationsDetails: string;
  OtherAuthorisationsExpirationDate?: string;
  ShipFromAddress: Address;
  TermsOfDelivery: CodeValue;
  RestrictedCountries: Country[];
  AogSupportContact: Address;
  CustomerServiceContact: Address;
  FinanceContact: Address;
  WarrantyOrClaimsContact: Address;
  Documents: MarketplaceShopDocument[];
  Departments: ShopContactDepartment[];
}

// ..\Satair.Core\Models\Domain\MarketplaceMasterShop.cs
export interface MarketplaceShopDocument {
  Id: string;
  Name: string;
  Type: string;
  Url: string;
  FriendlyName: string;
}

// ..\Satair.Core\Models\Domain\MarketplaceMasterShop.cs
export interface ShopAuthorisation {
  ExpirationDate?: string;
  Label: string;
  Status: string;
  AuthDocUrl: string;
  Document: MarketplaceShopDocument;
  LabelToDocType: string;
}

// ..\Satair.Core\Models\Domain\MarketplaceMasterShop.cs
export interface ShopContactDepartmentInfo {
  MainContactPerson: string;
  Email: string;
  PhoneNumber: string;
  MobilePhoneNumber: string;
  OpeningHours: string;
  TimeZone: string;
}

// ..\Satair.Core\Models\Domain\MarketplaceMasterShop.cs
export interface ShopContactDepartment {
  DepartmentName: string;
  ShopContactDepartmentInfos: ShopContactDepartmentInfo[];
}

// ..\Satair.Core\Models\Domain\MarketplaceMasterShop.cs
export interface PriorityFee {
  BusinessHours: string;
  Priority: CodeValue;
  Type: string;
  Value: number;
}

// ..\Satair.Core\Models\Domain\MarketPlaceMessageReq.cs
export interface MarketplaceMessageReq {
  Body: string;
  Subject: string;
  Attachments: MarketplaceMessageDocument[];
  ThreadId: string;
}

// ..\Satair.Core\Models\Domain\MarketPlaceMessageReq.cs
export interface MarketplaceMessageDocument {
  Name: string;
  Content: string;
}

// ..\Satair.Core\Models\Domain\MarketPlaceMessageReq.cs
export interface MarketplaceMessageResponse {
  MessageId: string;
  ThreadId: string;
}

// ..\Satair.Core\Models\Domain\MarketplaceMessagingConsignemtThreads.cs
export interface MarketplaceMessagingConsignemtThreads {
  ConsignmentId: string;
  ThreadId: string;
  WareHouseName: string;
  TotalMessageCount: number;
  MasterShopName: string;
  LocationDisplayName: string;
}

// ..\Satair.Core\Models\Domain\MarketplaceMessagingThread.cs
export interface MarketplaceMessagingThread {
  Messages: MarketplaceMessage[];
  Pagination: Pagination;
  CreatedDate: string;
  UpdatedDate: string;
  ThreadId: string;
  Subject: string;
  TotalMessageCount: number;
  ConsignmentId: string;
  MasterShopName: string;
  LocationDisplayName: string;
}

// ..\Satair.Core\Models\Domain\MarketplaceMessagingThread.cs
export interface MarketplaceMessage {
  AuthorName: string;
  AuthorType: string;
  Body: string;
  CreatedDate: string;
  Subject: string;
  AttachmentInfos: MarketplaceMessageAttachmentInfo[];
}

// ..\Satair.Core\Models\Domain\MarketplaceMessagingThread.cs
export interface MarketplaceMessageAttachmentInfo {
  Id: string;
  Name: string;
  Size: number;
}

// ..\Satair.Core\Models\Domain\MarketplaceMessagingThreads.cs
export interface MarketplaceMessagingThreads {
  OrderCode: string;
  Threads: MarketplaceMessagingThread[];
}

// ..\Satair.Core\Models\Domain\MasterShop.cs
export interface MasterShop {
  Id: string;
  Key: string;
  Name: string;
  PaymentTerms: string;
  TermsAndPolicies: MarketplaceShopDocument[];
}

// ..\Satair.Core\Models\Domain\MiraklDocument.cs
export interface MiraklDocument {
  DocumentDescription: string;
  DocumentLink: string;
}

// ..\Satair.Core\Models\Domain\OfferInfo.cs
export interface OfferInfo {
  Id: string;
  OfferId: string;
  ShopId: string;
  ShopName: string;
  State: string;
  MinimumOrderQuantity: number;
  PackageQuantity: number;
  DiscountPrice: ProductPrice;
  OriginalPrice: ProductPrice;
  DiscountPercentage: number;
  DiscountPercentageFormatted: string;
  DiscountEndDate: string;
  DiscountStartDate: string;
  DiscountEndDateAvailable: boolean;
  DiscountPriceAvailable: boolean;
  OrderedWithDiscountPrice: boolean;
}

// ..\Satair.Core\Models\Domain\OfferQuoteRequest.cs
export interface OfferQuoteRequest {
  Message: string;
  OfferId: string;
  OfferedBasePrice: string;
  ReferenceNumber: string;
  RequestedQuantity: string;
  ShippingAddressId: string;
  Subject: string;
}

// ..\Satair.Core\Models\Domain\OfferQuoteResponse.cs
export interface OfferQuoteResponse {
  Code: string;
  SapQuoteId: string;
  FormattedCode: string;
  FormattedSapQuoteId: string;
  DeliveryAddress: Address;
  Entries: OfferQuoteEntry[];
}

// ..\Satair.Core\Models\Domain\OfferQuoteResponse.cs
export interface OfferQuoteEntry {
  MasterShopId: string;
  OfferInfo: OfferInfo;
  InStock: boolean;
  InfoStatus: string;
  MasterShop: MarketplaceMasterShop;
  Offers: ShopOffer[];
  PriceAvailable: boolean;
  PriceUnit: Unit;
  PriceUnitFactor: number;
  PriorityInfo: PriorityInfo;
  Product: Product;
  Quantity: number;
  RemainingQuantity: number;
  ReturnableQuantity: number;
  ReturnedItemsPrice: ProductPrice;
  SalesUnit: Unit;
  Fees: FeeItem[];
  SapWarehouseType: string;
  TotalPrice: ProductPrice;
  UnknownCageCode: string;
  UnknownName: string;
  UnknownPartNumber: string;
  Updateable: boolean;
}

// ..\Satair.Core\Models\Domain\OfferQuoteResponses.cs
export interface OfferQuoteResponses {
  Messages: QuoteMessage[];
  QuoteResponses: QuoteResponse[];
  Subject: string;
}

// ..\Satair.Core\Models\Domain\OfferQuoteResponses.cs
export interface QuoteMessage {
  Body: string;
  CreatedDate: string;
  FromType: string;
  Id: string;
  ToType: string;
  AuthorName: string;
  AuthorType: string;
  AttachmentInfos: MarketplaceMessageAttachmentInfo[];
  MasterShopName: string;
  LocationDisplayName: string;
}

// ..\Satair.Core\Models\Domain\OfferQuoteResponses.cs
export interface QuoteResponse {
  Entries: QuoteResponseEntry[];
  ExpirationDate: string;
  MiraklQuoteResponseId: string;
  Price: ProductVolumePrice;
  ShippingPrice: ProductVolumePrice;
  State: string;
  TotalPrice: ProductVolumePrice;
}

// ..\Satair.Core\Models\Domain\OfferQuoteResponses.cs
export interface QuoteResponseEntry extends CartEntry {
  PriceAdditionalInfo: string;
}

// ..\Satair.Core\Models\Domain\Order.cs
export interface Order extends OrderEntry {
  Entries: OrderLine[];
  SellerGroup: SellerGroup[];
  DeliveryAddress: Address;
  Carrier: Carrier;
  CarrierInfo: string;
  Airframe: string;
  Reference: string;
  User: User;
  HazmatFee: string;
  RoutineFee: string;
  AirWayBillNo: string;
  CustomerNo: string;
  TermsOfDelivery: CodeValue;
  NamedDeliveryDate?: string;
  ConfirmedDeliveryDate: string;
  Documents: OrderDocument[];
  DocumentsGrouped: OrderDocument[][];
  Certificates: string[];
  Fees: Fee[];
  OrderDetailsDisplayList: DisplayListItem[];
  OrderDownloadsDisplayList: DisplayListItem[];
  OrderShippingDisplayList: DisplayListItem[];
  OrderType: CodeValue;
  Consignments: Consignment[];
  UnconsignedEntries: ConsignmentEntry[];
  ShowTotal: boolean;
  ShowSubTotal: boolean;
  CarrierInstructions: string;
  QuoteCode: string;
  IsQuoteOrder: boolean;
  ReferencedQuotes: ReferencedQuote[];
  TermsOfPayment: CodeDescription;
  ConsignmentDocuments: Document[];
  HasMinOrderLineValue: boolean;
  ForwarderInfo: Forwarder;
  UnknownDeliveryDate: boolean;
  OrderToReview: boolean;
  IsAogOrder: boolean;
  RequiresCashInAdvance: boolean;
  BlockedPendingPayment: boolean;
  ConsignmentThreadsList: MarketplaceMessagingConsignemtThreads[];
  OwningSystem: string;
  AirbusExtendedOrderDetails?: AirbusExtendedOrderDetails;
  AirbusProcurementType?: string;
}

// ..\Satair.Core\Models\Domain\OrderChangeLogItem.cs
export interface OrderChangeLogItem {
  ModificationTime: string;
  Category: string;
  AttributeName: string;
  Description: string;
  Type: string;
  NewValue: string;
  OldValue: string;
  ModifiedItemId: string;
  EntryIndex: string;
  PartNumber: string;
  CageCode: string;
  ContainerId: string;
}

// ..\Satair.Core\Models\Domain\OrderChanges.cs
export interface OrderChanges {
  Changes: OrderChange[];
}

// ..\Satair.Core\Models\Domain\OrderChanges.cs
export interface OrderChange {
  AttributeName: string;
  InfoStatus: string;
  ModificationTime: string;
  ModificationType: string;
  ModifiedItemId: string;
  ModifiedItemType: string;
  NewValue: string;
  OldValue: string;
}

// ..\Satair.Core\Models\Domain\OrderDocument.cs
export interface OrderDocument {
  DocumentNumber: string;
  DocumentCategory: string;
  Description: string;
  MaterialNumberExternal: string;
  DocumentUrl: string;
  ReferenceToBoxNumber: string;
  Code: string;
  FileName: string;
  Type: string;
  ConsignmentCode: string;
}

// ..\Satair.Core\Models\Domain\OrderEntry.cs
export interface OrderEntry {
  Code: string;
  FormattedCode: string;
  DeliveryCost: ProductPrice;
  PriorityInfo: PriorityInfo;
  PurchaseOrderNumber: string;
  SubTotal: ProductPrice;
  TotalPrice: ProductPrice;
  Created: string;
  StatusDisplay: OrderStatus;
  OriginDisplay: string;
  ProjectID: string;
  MasterShopId: string;
  MasterShop: MasterShop;
  IsSatairOrder: boolean;
  SupplierDisplay: string;
  EusRequiredOrExportBlocked: boolean;
  RequiresCashInAdvance: boolean;
  BlockedPendingPayment: boolean;
  AirbusSapOrderId: string;
  AirbusExtendedOrderDetails?: AirbusExtendedOrderDetails;
  OwningSystem: string;
  OrderType: CodeValue;
}

// ..\Satair.Core\Models\Domain\OrderLine.cs
export interface OrderLine extends CartEntry {
  OrderData: OrderLineConsignment[];
  StatusDisplay: string;
  CancelReason: string;
  CancelReasonCode: string;
  Cancelled: boolean;
  Consignments: Consignment[];
  AirbusEntryPriority: string;
}

// ..\Satair.Core\Models\Domain\OrderLineConsignment.cs
export interface OrderLineConsignment {
  Quantity: number;
  Location: CodeValue;
  DeliveryDate?: string;
  RequestedDate: string;
  Priority: CodeValue;
  Forwarder: string;
  StatusDisplay: string;
  EstimatedDate: boolean;
  TrackingId: string;
  Container: ConsignmentContainer[];
  UnknownDeliveryDate: boolean;
  BatchNumber: string;
}

// ..\Satair.Core\Models\Domain\OrderOfferQuoteRequest.cs
export interface OrderOfferQuoteRequest {
  ForwarderId: string;
  ForwarderInfo: string;
  NamedDeliveryDate: string;
  PriorityInfo: PriorityInfo;
  ProjectId: string;
  PurchaseOrderNumber: string;
  QuoteEntries: QuoteEntry[];
  ShippingAddressId: string;
}

// ..\Satair.Core\Models\Domain\OrderOfferQuoteRequest.cs
export interface QuoteEntry {
  EntryNumber: number;
  Quantity: number;
}

// ..\Satair.Core\Models\Domain\OrderReceipt.cs
export interface OrderReceipt {
  OrderResults: OrderReceiptItem[];
}

// ..\Satair.Core\Models\Domain\OrderReceipt.cs
export interface OrderReceiptItem {
  MasterShopId: string;
  Order: Order;
}

// ..\Satair.Core\Models\Domain\OrdersList.cs
export interface OrdersList {
  Orders: OrderEntry[];
  Pagination: Pagination;
  Sorts: Sorts[];
  Facets: Facets[];
  Count: number;
  Page: number;
  TotalPages: number;
}

// ..\Satair.Core\Models\Domain\PageReference.cs
export interface References {
  Key: string;
  Reference: ReferenceItem;
}

// ..\Satair.Core\Models\Domain\PageReference.cs
export interface ReferenceItem {
  Url: string;
  Name: string;
  PageUrl: string;
  ModalUrl: string;
}

// ..\Satair.Core\Models\Domain\Pagination.cs
export interface Pagination {
  TotalResults: string;
  TotalPages: string;
  CurrentPage: string;
}

// ..\Satair.Core\Models\Domain\PriorityInfo.cs
export interface PriorityInfo {
  AcReg: string;
  AcType: string;
  Airframe: string;
  Deadline: string;
  LastDockoutDate: string;
  Priority: CodeValue;
  PriorityReason: string;
}

export enum OwningSystem {
  RED = 'RED',
  BLUE = 'BLUE',
  MIRAKL = 'MIRAKL',
  LECACY_BLUE = 'LEGACY_BLUE',
}

export interface AirbusCertificateForSalesNew {
  Code: string;
  FormattedName: string;
  Name: string;
}

// ..\Satair.Core\Models\Domain\Product.cs
export interface Product {
  Id: string;
  Code: string;
  MaterialNumberExternal: string;
  Name: string;
  Description: string;
  Summary: string;
  OfferUrl: string;
  MarketOfferUrl: string;
  ManufacturerAid: string;
  ProductManufacturer: ProductManufacturer;
  Sku: string;
  Certificate: string;
  IsAirbusHazmat: boolean;
  HazmatDescription: string;
  HazmatCode: string;
  PackageQuantity: number;
  RoundingValue: number;
  AddToBasketQty: number;
  CartStep: number;
  MaxShelfLife: string;
  LifeTimeIndicator: string;
  Applicabilities: CodeValue[];
  AtaChapters: CodeValue[];
  Height: string;
  Width: string;
  Length: string;
  GrossWeight: string;
  NetWeight: string;
  WeightUnit: string;
  VolumeUnit: string;
  DimensionUnit: string;
  Volume: string;
  MultiDimensional: boolean;
  UnitConversions: UnitConversion[];
  UnitConversionDisplayList: string[];
  Price: ProductPrice;
  PriceHidden: boolean;
  HasPrice: boolean;
  VolumePrices: ProductVolumePrice[];
  HasVolumePrices: boolean;
  ProductRule: ProductRule;
  ShowStock: boolean;
  ShowPrice: boolean;
  ShowOrderButton: boolean;
  RuleStatus: string;
  LimitedByCageCode: boolean;
  IsUnknownProduct: boolean;
  HasHazmat: boolean;
  HasImage: boolean;
  HasCertificate: boolean;
  Unit: string;
  SapBlocked?: boolean;
  Images: ProductImages[];
  Supplier: string;
  Channel: string;
  MasterData: DisplayListItemProduct[];
  Measurements: DisplayListItemProduct[];
  OfferData: DisplayListItemProduct[];
  ExportControlData: DisplayListItemProduct[];
  Interchangeable: boolean;
  AirbusProprietary: boolean;
  IsMarketplace: boolean;
  Offers: ProductOffer[];
  SupplierDisplay: string;
  SlowMoverPriceAvailable: boolean;
  SerialNumberUse: string;
  SerialNumberRequired?: boolean;
  Eccn: string;
  Function: string;
  MaterialComposition: string;
  NextHigherAssembly: string;
  Colour: string;
  AlternativePartNumbers: string[];
  ManufacturingCountry: CodeValue;
  Cml: string;
  AllowQuoteRequests: boolean;
  IsZtel: boolean;
  Flammable: boolean;
  OwningSystem: string;
  State: string;
  ShowNewSupplierLogo: boolean;
  NewSupplierLogoCdnUrl: string;
  NewSupplierLogoUrl: string;
  ShowSupplierLogo: boolean;
  SupplierLogoCdnUrl: string;
  SupplierLogoUrl: string;
  EnginePart: boolean;
  SupplierLogo: string;
  PartNumberInterchangeableMatch: boolean;
  AirbusCertificateForSalesNew: AirbusCertificateForSalesNew;
  IsKit: boolean;
  IsTool?: boolean;
  InterchangeableMatchedPartNumber?: string;
  MainPicture?: string;
}

// ..\Satair.Core\Models\Domain\ProductAdditionalInfo.cs
export interface ProductAdditionalInfo {
  Id: string;
  Code: string;
  Quantity: number;
  BasePrice: ProductPrice;
  Price: ProductPrice;
  PriceUnit: Unit;
  SalesUnit: Unit;
  IsInStock: boolean;
  StockIndication: string;
  ProductRule: ProductRule;
  ShowStock: boolean;
  ShowPrice: boolean;
  ShowOrderButton: boolean;
  RuleStatus: string;
  Seller: string;
  Warehouse: CodeValue;
  EnableCustomizeWarehouse: boolean;
  HasMultipleOffers: boolean;
  ZproPriceAvailable: boolean;
  SlowMoverPriceAvailable: boolean;
  Availabilities: ProductAvailable[];
  IsAsmHidden: boolean;
  UnknownDeliveryDate: boolean;
  OfferSku: string;
  RemainingOfferQuantity: number;
  Quotable: boolean;
  DiscountPrice: ProductPrice;
  OriginalPrice: ProductPrice;
  DiscountPercentage: number;
  DiscountPercentageFormatted: string;
  DiscountEndDate: string;
  DiscountStartDate: string;
  DiscountEndDateAvailable: boolean;
  DiscountPriceAvailable: boolean;
  State?: string;
}

// ..\Satair.Core\Models\Domain\ProductAvailability.cs
export interface ProductAvailability {
  IsInStock: boolean;
  StockIndication: string;
}

// ..\Satair.Core\Models\Domain\ProductAvailabilityEntry.cs
export interface ProductAvailabilityEntry extends ProductEntryBase {
  ProductRule: ProductRule;
  ProductAvailabilityInfos: ProductAvailabilityInfo[];
}

// ..\Satair.Core\Models\Domain\ProductAvailabilityEntry.cs
export interface ProductAvailabilityInfo {
  ErrorMessage: string;
  SapWareHouseType: string;
  InStock: boolean;
  Warehouse: CodeValue;
  ProductAvailabilities: CartAvailability[];
  InfoStatus: string;
  IsAsmHidden: boolean;
  UnknownDeliveryDate: boolean;
}

// ..\Satair.Core\Models\Domain\ProductAvailable.cs
export interface ProductAvailable {
  Code: string;
  InStock: boolean;
  AvailableDate?: string;
  StockCount: number;
  Warehouse: CodeValue;
  BasePrice: ProductPrice;
  TotalPrice: ProductPrice;
  AvailableStockQuantity?: number;
  ErrorMessage: string;
  InfoStatus: string;
  IsAsmHidden: boolean;
  UnknownDeliveryDate: boolean;
  EntryNumber?: number;
}

// ..\Satair.Core\Models\Domain\ProductEntryBase.cs
export interface ProductEntryBase {
  Id: string;
  Quantity: number;
  ErrorMessage: string;
}

// ..\Satair.Core\Models\Domain\ProductEntryBase.cs
export interface ProductOfferBase {
  ErrorMessage: string;
  Seller: string;
  SellerId: string;
}

// ..\Satair.Core\Models\Domain\ProductImages.cs
export interface ProductImages {
  AltText: string;
  Format: string;
  ImageType: string;
  Url: string;
  CdnUrl: string;
  GalleryIndex?: number;
}

// ..\Satair.Core\Models\Domain\ProductInterchangeableItem.cs
export interface ProductInterchangeableItem {
  Id: string;
  TwoWay: boolean;
  Pno: string;
  CageCode: string;
  Sku: string;
}

// ..\Satair.Core\Models\Domain\ProductList.cs
export interface ProductList extends CodeValue {
  CreatedBy: User;
  CreationDate: string;
  Entries: ProductListEntry[];
  LastModifiedDate: string;
  ProductListType: string;
  NumberOfEntries?: number;
  UnresolvedEntries: ProductListErrors[];
}

// ..\Satair.Core\Models\Domain\ProductListEntry.cs
export interface ProductListEntry {
  Id: string;
  AddedDate: string;
  Product: Product;
  Offer: ProductOffer;
  BasePrice: ProductPrice;
  TotalPrice: ProductPrice;
  Quantity?: number;
  PriceUnit: string;
  SalesUnit: string;
  State: string;
  ContainsUnknownMaterial: boolean;
  UnknownCageCode: string;
  UnknownName: string;
  UnknownPartNumber: string;
  OfferedTotalPrice: ProductPrice;
  OfferedBasePrice: ProductPrice;
}

// ..\Satair.Core\Models\Domain\ProductListErrors.cs
export interface ProductListErrors {
  AddedDate: string;
  CageCode: string;
  PartNumber: string;
  Quantity?: number;
  SuggestedProducts: ProductSuggestions;
  IsLimitedByCageCode: boolean;
  Code: string;
}

// ..\Satair.Core\Models\Domain\ProductListsList.cs
export interface ProductListsList {
  ProductLists: ProductList[];
  RecentProductLists: ProductList[];
  Pagination: Pagination;
  Sorts: Sorts[];
  Facets: Facets[];
  Count: number;
  Page: number;
  TotalPages: number;
}

// ..\Satair.Core\Models\Domain\ProductManufacturer.cs
export interface ProductManufacturer {
  CageCode: string;
  Name: string;
}

// ..\Satair.Core\Models\Domain\ProductOffer.cs
export interface ProductOffer {
  AllowQuoteRequests: boolean;
  PriceHidden: boolean;
  Id: string;
  Shop: Shop;
  State: string;
  Product: Product;
  ShopName: string;
  ShopCode: string;
  ShopId: string;
  MasterShopName: string;
  VisibleWear?: boolean;
  LastMaintenanceOrganisation: string;
  DateOfManufacture?: string;
  LastArcIssueDate?: string;
  SerialNumber: string;
  DeliveryTerms: string;
  InfoStatus: string;
  IsAsmHidden: boolean;
  Documents: MiraklDocument[];
  DocumentsLinks: string[];
  DocumentsNames: string[];
  Description: string;
  WarrantyType: string;
  WarrantyExpirationDate?: string;
  ExpirationDate?: string;
  TraceType: string;
  CyclesRemaining: number;
  CyclesSinceNew: number;
  TimeRemaining: number;
  TimeSinceNew: number;
  Inactive: boolean;
  Quantity: number;
  SupplierLogo: string;
}

// ..\Satair.Core\Models\Domain\ProductPlants.cs
export interface ProductPlants {
  Details: Detail[];
  ErrorMessage: string;
  Id: string;
}

// ..\Satair.Core\Models\Domain\ProductPlants.cs
export interface Detail {
  InStock: boolean;
  Quantity: number;
  Unit: Unit;
  Warehouse: CodeValue;
}

// ..\Satair.Core\Models\Domain\ProductPlants.cs
export interface Unit {
  Code: string;
  Name: string;
  SapCode: string;
}

// ..\Satair.Core\Models\Domain\ProductPlants.cs
export interface Warehouse {
  Code: string;
  Name: string;
}

// ..\Satair.Core\Models\Domain\ProductPlantsList.cs
export interface ProductPlantsList {
  Plants: Plant[];
  HasPlants: boolean;
  Id: string;
  Code: string;
}

// ..\Satair.Core\Models\Domain\ProductPlantsList.cs
export interface Plant {
  InStock: boolean;
  Quantity?: number;
  Unit: string;
  Warehouse: string;
}

// ..\Satair.Core\Models\Domain\ProductPrice.cs
export interface ProductPrice {
  CurrencyIso: string;
  FormattedValue: string;
  PriceType: string;
  Value: string;
  InfoStatus: string;
  IsAsmHidden: boolean;
}

// ..\Satair.Core\Models\Domain\ProductPriceEntry.cs
export interface ProductPriceEntry extends ProductEntryBase {
  ProductRule: ProductRule;
  ProductPriceInfo: ProductPriceInfo;
  ShowStock: boolean;
  ShowPrice: boolean;
  ShowOrderButton: boolean;
  RuleStatus: string;
  SlowMoverPriceAvailable: boolean;
  DiscountPriceAvailable: boolean;
  DiscountEndDateAvailable: boolean;
}

// ..\Satair.Core\Models\Domain\ProductPriceEntry.cs
export interface ProductPriceInfo extends ProductOfferBase {
  Price: ProductVolumePrice;
  BasePrice: ProductVolumePrice;
  PriceBreakInfos: PriceBreakInfo[];
  PriceUnit: Unit;
  SalesUnit: Unit;
  HasPriceBreaks: boolean;
  SlowMoverPriceAvailable: boolean;
  DiscountPrice: ProductPrice;
  OriginalPrice: ProductPrice;
  DiscountPercentage: number;
  DiscountPercentageFormatted: string;
  DiscountEndDate: string;
  DiscountStartDate: string;
}

// ..\Satair.Core\Models\Domain\ProductPriceEntry.cs
export interface PriceBreakInfo {
  BasePrice: ProductVolumePrice;
  FormattedVolumeDiscountPercentage: string;
  PriceUnit: Unit;
  PriceUnitFactor: number;
  MinQuantity: number;
  MaxQuantity: number;
  VolumeDiscountPercentage: number;
}

// ..\Satair.Core\Models\Domain\ProductPriceList.cs
export interface ProductPriceList {
  Code: string;
  Quantity: number;
  BasePrice: ProductPrice;
  Price: ProductPrice;
  PriceBreaks: ProductVolumePrice[];
}

// ..\Satair.Core\Models\Domain\ProductRule.cs
export interface ProductRule {
  PriceVisible: string;
  Sellable: string;
  StockVisible: string;
  Quotable: string;
}

// ..\Satair.Core\Models\Domain\ProductSearch.cs
export interface ProductSearch extends IProductFacet {
  Products: ProductSearchItem[];
  Count: number;
  Page: number;
  TotalPages: number;
  Facets: Facets[];
  WarningEnabled: boolean;
  WarningText: string;
}

// ..\Satair.Core\Models\Domain\ProductSearchInfo.cs
export interface ProductSearchInfo {
  Price: ProductPrice;
  HasVolumePrice: boolean;
  MaxVolumePriceText: string;
  ShowPrice: boolean;
  ShowOrderButton: boolean;
  ShowStock: boolean;
  RuleStatus: string;
  AddToBasketQty: number;
  CartStep: number;
}

// ..\Satair.Core\Models\Domain\ProductSearchItem.cs
export interface ProductSearchItem {
  PriceHidden: boolean;
  AllowQuoteRequests: boolean;
  Description: string;
  Summary: string;
  Id: string;
  Code: string;
  MaterialNumberExternal: string;
  OfferUrl: string;
  MarketOfferUrl: string;
  Name: string;
  ManufacturerAid: string;
  ProductManufacturer: ProductManufacturer;
  Sku: string;
  Certificate: string;
  HazmatCode: string;
  HazmatDescription: string;
  HasHazmat: boolean;
  HasImage: boolean;
  HasCertificate: boolean;
  Unit: string;
  UnitConversionDisplayList: string[];
  PackageQuantity: number;
  RoundingValue: number;
  AddToBasketQty: number;
  CartStep: number;
  Images: ProductImages[];
  Interchangeable: boolean;
  AirbusProprietary: boolean;
  Origin: string;
  IsMarketplace: boolean;
  SupplierDisplay: string;
  PartNumberInterchangeableMatch: boolean;
  State: string;
  Certificates: string[];
  IsZtel: boolean;
  SupplierLogoUrl: string;
  SupplierLogoCdnUrl: string;
  ShowSupplierLogo: boolean;
  OwningSystem: OwningSystem;
}

// ..\Satair.Core\Models\Domain\ProductStock.cs
export interface ProductStock {
  Location: string;
  Quantity: number;
  Leadtime: string;
  Unit: CodeValue;
  DefaultLocation: boolean;
}

// ..\Satair.Core\Models\Domain\ProductSuggestions.cs
export interface ProductSuggestions {
  SuggestionType: string;
  Products: ProductSearchItem[];
}

// ..\Satair.Core\Models\Domain\ProductVolumePrice.cs
export interface ProductVolumePrice extends ProductPrice {
  MinQuantity: number;
  MaxQuantity: number;
  FormattedVolumeDiscountPercentage: string;
  VolumeDiscountPercentage: number;
}

// ..\Satair.Core\Models\Domain\Quote.cs
export interface Quote {
  Calculated: boolean;
  Code: string;
  CreationTime: string;
  CustomerEmail: string;
  FormattedCode: string;
  OriginDisplay: string;
  Net: boolean;
  ProjectId: string;
  PurchaseOrderNumber: string;
  QuoteExpirationDate: string;
  SapQuoteId: string;
  FormattedSapQuoteId: string;
  ValidFrom: string;
  State: string;
  UpdatedTime: string;
  DeliveryCost: ProductPrice;
  SubTotal: ProductPrice;
  TotalDiscounts: ProductPrice;
  TotalPrice: ProductPrice;
  TotalTax: ProductPrice;
  TermsOfPayment: CodeDescription;
  MasterShop: Shop;
  Expired: boolean;
  QuoteDateExpired: boolean;
  DaysLeft: number;
  QuoteDisplayId: string;
  ReferenceNumber: string;
  IsMarketplace: boolean;
}

// ..\Satair.Core\Models\Domain\QuoteDetail.cs
export interface QuoteDetail extends Quote {
  HasCart: boolean;
  TotalItems: number;
  Version: number;
  Entries: CartEntry[];
  DeliveryAddress: Address;
  Tags: string[];
  OrderDiscounts: ProductPrice;
  ProductDiscounts: ProductPrice;
  TotalPriceWithTax: ProductPrice;
  PreviousEstimatedValue: ProductPrice;
  TermsOfDelivery: CodeValue;
  QuoteDetailsDisplayList: DisplayListItem[];
  QuoteShippingDisplayList: DisplayListItem[];
  ShowTotal: boolean;
  OfferQuoteResponses: QuoteResponse[];
  Messages: QuoteMessage[];
  Threads: MarketplaceMessagingThread[];
  HasMinOrderLineValue: boolean;
  ReferencingOrders: ReferencingOrder[];
  SellerGroup: SellerGroup;
  HasMarketplaceSellers: boolean;
  TotalPriceWithShipping: ProductVolumePrice;
  MiraklQuoteRequestId: string;
  Warehouse: CodeValue;
  Unit: B2BUnit;
  MasterShopName: string;
  LocationDisplayName: string;
}

// ..\Satair.Core\Models\Domain\QuoteEntryRequest.cs
export interface QuoteEntryRequest {
  EntryNumber: number;
  Quantity: number;
}

// ..\Satair.Core\Models\Domain\QuoteList.cs
export interface QuoteList {
  CustomerEmail: string;
  Priority: string;
  RequestedDate: string;
  ReqDate?: string;
  ShippingAddressId: string;
  Reference: string;
  QuoteItems: QuoteListItem[];
}

// ..\Satair.Core\Models\Domain\QuoteList.cs
export interface QuoteListItem {
  Id: string;
  RequestedQuantity: string;
}

// ..\Satair.Core\Models\Domain\QuoteListValidationResponse.cs
export interface QuoteListValidationResponse {
  ListValid: boolean;
  Results: QuoteListValidResults[];
}

// ..\Satair.Core\Models\Domain\QuoteListValidationResponse.cs
export interface QuoteListValidResults {
  Errors: string[];
  Product: Product;
}

// ..\Satair.Core\Models\Domain\QuotePriority.cs
export interface QuotePriority {
  AcReg: string;
  AcType: string;
  Airframe: string;
  Priority: CodeValue;
}

// ..\Satair.Core\Models\Domain\QuotesSearch.cs
export interface QuotesSearch {
  Facets: Facets[];
  Pagination: Pagination;
  Quotes: Quote[];
  Sorts: Sorts[];
  Count: number;
  Page: number;
  TotalPages: number;
}

// ..\Satair.Core\Models\Domain\QuoteToOrder.cs
export interface QuoteToOrder {
  ForwarderId: string;
  CustomForwarderInfo: CustomForwarderInformation;
  ForwarderInfo: string;
  NamedDeliveryDate: string;
  PriorityInfo: QuotePriority;
  ProjectId: string;
  PurchaseOrderNumber: string;
  QuoteEntries: QuoteEntryRequest[];
  ShippingAddressId: string;
  QuoteResponseId: string;
  ForwarderAccountNumber: string;
  ServiceLevelAgreements: string;
}

// ..\Satair.Core\Models\Domain\QuoteToOrder.cs
export interface CustomForwarderInformation {
  AdditionalInfo: string;
  ForwarderAccountNumber: string;
  ForwarderName: string;
  LocalContactInfo: string;
}

// ..\Satair.Core\Models\Domain\QuoteTotalRepsonse.cs
export interface QuoteTotalRepsonse {
  Entries: QuoteTotalResponseEntry[];
  TotalPrice: ProductPrice;
}

// ..\Satair.Core\Models\Domain\QuoteTotalRepsonse.cs
export interface QuoteTotalResponseEntry extends QuoteEntryRequest {
  PriceUnitFactor: number;
  BasePrice: ProductPrice;
  TotalPrice: ProductPrice;
  PriceUnit: Unit;
  SalesUnit: Unit;
}

// ..\Satair.Core\Models\Domain\Recommendation.cs
export interface Recommendation {
  Sku: string;
  Items: RecommendationItem[];
}

// ..\Satair.Core\Models\Domain\Recommendation.cs
export interface RecommendationItem {
  Sku: string;
  Pno: string;
  CageCode: string;
}

// ..\Satair.Core\Models\Domain\ReferencedQuote.cs
export interface ReferencedQuote {
  Code: string;
  CreationTime?: string;
  CustomerEmail: string;
  ModifiedTime?: string;
  PurchaseOrderNumber: string;
  QuoteExpirationDate?: string;
  SapQuoteId: string;
  FormattedCode: string;
  FormattedSapQuoteId: string;
}

// ..\Satair.Core\Models\Domain\ReferencingOrder.cs
export interface ReferencingOrder {
  Code: string;
  PurchaseOrderNumber: string;
}

// ..\Satair.Core\Models\Domain\RemovedCartEntry.cs
export interface RemovedCartEntry {
  CageCode: string;
  EntryNumber: number;
  OfferId: string;
  PartNumber: string;
  Quantity: number;
  RemovalReason: string;
}

// ..\Satair.Core\Models\Domain\Rfq.cs
export interface Rfq {
  Code: string;
  CreationTime: string;
  Guid: string;
  State: string;
  UpdatedTime: string;
}

// ..\Satair.Core\Models\Domain\SellerGroup.cs
export interface SellerGroup {
  Key: string;
  Name: string;
  NamedDeliveryDate?: string;
  Carrier: Carrier;
  CarrierInfo: string;
  CarrierInstructions: string;
  Logo: string;
  ServiceLevelAgreements: string;
  DeliveryAddress: Address;
  PriorityInfo: PriorityInfo;
  PriorityFee: PriorityFee;
  Price: ProductPrice;
  EntriesCount: number;
  Entries: CartEntry[];
  HasMinOrderLineValue: boolean;
  HasMinOrderValue: boolean;
  HasAddressRestrictedEntries: boolean;
  MinOrderLineValue: number;
  MinOrderValue: number;
  TermsAndPolicies: MarketplaceShopDocument[];
  Url: string;
}

// ..\Satair.Core\Models\Domain\Shop.cs
export interface Shop {
  Id: string;
  Name: string;
  LocationDisplayName: string;
  MasterShop: MasterShop;
  TermsOfDelivery: CodeValue;
}

// ..\Satair.Core\Models\Domain\ShopOffer.cs
export interface ShopOffer {
  Key: string;
  Value: OfferContainer;
}

// ..\Satair.Core\Models\Domain\ShopOffer.cs
export interface OfferContainer {
  Offers: OfferItem[];
}

// ..\Satair.Core\Models\Domain\ShopOffer.cs
export interface OfferItem {
  Code: string;
  Id: string;
  SalesUnit: Unit;
  Shop: Shop;
  State: string;
  PriceHidden: boolean;
  AllowQuoteRequests: boolean;
}

// ..\Satair.Core\Models\Domain\SimpleCart.cs
export interface SimpleCart {
  Code: string;
  DeliveryAddress: Address;
  EntryCount: string;
  Entries: CartEntry[];
  RawEntries: CartEntry[];
  HasEntriesWithMultipleWarehouse: boolean;
  HasAddressRestrictedEntries: boolean;
  SellerGroup: SellerGroup[];
}

// ..\Satair.Core\Models\Domain\SimpleCart.cs
export interface SimpleCartEntry {
  EntryNumber: number;
  Code: string;
  Quantity: number;
  Product: SimpleProduct;
}

// ..\Satair.Core\Models\Domain\SimpleCart.cs
export interface SimpleProduct {
  CertificateType: CodeValue;
  Code: string;
  ManufacturerAid: string;
  ManufacturerData: ProductManufacturer;
  Name: string;
}

// ..\Satair.Core\Models\Domain\SimplyFlyEntry.cs
export interface SimplyFlyEntry {
  Code: string;
  Company: string;
  Attention: string;
  Address: string;
  Zip: string;
  City: string;
  Country: string;
  Email: string;
  MagOnline: boolean;
  MagPhysical: boolean;
  Copies: string;
  Terms: boolean;
}

// ..\Satair.Core\Models\Domain\Sorts.cs
export interface Sorts {
  Code: string;
  Selected: boolean;
}

// ..\Satair.Core\Models\Domain\StockQuantity.cs
export interface StockQuantity {
  Quantity: number;
  Unit: CodeValue;
}

// ..\Satair.Core\Models\Domain\Tracking.cs
export interface TrackingRoot {
  Apply: boolean;
  Page: TrackingPage;
  User: TrackingUser;
  Detail: TrackingDetail;
  Checkout: TrackingCart;
  Cart: TrackingCart;
  Order: TrackingOrder;
  Orders: TrackingOrder[];
  Upload: TrackingUpload;
  Event: TrackingEvent;
}

// ..\Satair.Core\Models\Domain\Tracking.cs
export interface TrackingCart {
  Product: TrackingProduct;
  ItemsInBasket: string;
  CustomizeImpressions: string;
}

// ..\Satair.Core\Models\Domain\Tracking.cs
export interface TrackingDetail {
  Product: TrackingProduct;
}

// ..\Satair.Core\Models\Domain\Tracking.cs
export interface TrackingEvent {
  Action: string;
  ActionType: string;
  ActionPage: string;
  FileType: string;
}

// ..\Satair.Core\Models\Domain\Tracking.cs
export interface TrackingUpload {
  FileType: string;
  Results?: number;
  NotIdentified?: number;
  Action: string;
}

// ..\Satair.Core\Models\Domain\Tracking.cs
export interface TrackingOrder {
  PurchaseId: string;
  CustomerPurchaseNumber: string;
  OrderTotal: string;
  UniqueProducts: string;
  ShipingForwarder: string;
  Priority: string;
  ProjectId: string;
  ShippingDate: string;
  MsnNo: string;
  AcType: string;
  AcRegistration: string;
  Product: TrackingProduct;
}

// ..\Satair.Core\Models\Domain\Tracking.cs
export interface TrackingPage {
  PageName: string;
  PageType: string;
  SiteSection1: string;
  SiteSection2: string;
  SiteSection3: string;
  SiteSection4: string;
  SiteSection5: string;
  SiteSection6: string;
  SiteSection7: string;
  ErrorPage: string;
  ErrorMessage: string;
  IpAddress: string;
}

// ..\Satair.Core\Models\Domain\Tracking.cs
export interface TrackingUser {
  Status: string;
  HashedEmail: string;
  CustomerType: string;
  CustomerId: string;
  SalesOrg: string;
  Action: string;
  AccountNumber: string;
  IsAsm: boolean;
  NewAccount?: boolean;
  ActivationDate: string;
  CustomerName: string;
}

// ..\Satair.Core\Models\Domain\Tracking.cs
export interface TrackingProduct {
  Manufacturer: string;
  Supplier: string;
  Items: TrackingProductItem[];
}

// ..\Satair.Core\Models\Domain\Tracking.cs
export interface TrackingProductItem {
  Sku: string;
  OfferSku: string;
  Pnr: string;
  CageCode: string;
  Name: string;
  Description: string;
  Price: string;
  Status: string;
  BulkDiscount: string;
  Channel: string;
  Quantity?: number;
  Code: string;
}

// ..\Satair.Core\Models\Domain\Tracking.cs
export interface InteractionTracking {
  Label: string;
  Action: string;
  Component: string;
  Value: string;
}

// ..\Satair.Core\Models\Domain\UnitConversion.cs
export interface UnitConversion {
  BaseUnit: Unit;
  BaseUnitQuantity: number;
  DestinationUnit: Unit;
  DestinationUnitQuantity: number;
  IsSalesUnitConversion: boolean;
}

// ..\Satair.Core\Models\Domain\User.cs
export interface User {
  Name: string;
  FirstName: string;
  LastName: string;
  DisplayUid: string;
  Uid: string;
  CustomerId: string;
  GlobalId: string;
  DefaultUnitUid: string;
  ParentUnitUid: string;
  Roles: string[];
  UserRoles: string[];
  Title: string;
  Email: string;
  TimeZone: string;
  Password: string;
  ContactNumber: string;
  LoginDisabled: boolean;
  PrimaryWorkArea: string;
  IsAdmin: boolean;
  ReadOnly: boolean;
  IsReadOnly: boolean;
  FinanceCustomer: boolean;
  IsFinance: boolean;
  CountryCode: string;
  IsFirstTime?: boolean;
  LinkedAccounts: UserLinkedAccount[];
  BetaCustomer: boolean;
  Admin: boolean;
  Active: boolean;
  Unit?: B2BUnit;
  RedCustomer: boolean;
  BlueCustomer: boolean;
  LastLoginNoDays: string;
  LastLogin: string;
  ActivationDate: string;
  HashedEmail: string;
  IsAsm: string;
  ProvisionedByAirbusWorld: boolean;
}

// ..\Satair.Core\Models\Domain\UserCreate.cs
export interface UserCreate {
  Name: string;
  GlobalId: string;
  Email: string;
}

// ..\Satair.Core\Models\Domain\UserLinkedAccounts.cs
export interface UserLinkedAccounts {
  LinkedAccounts: UserLinkedAccount[];
}

export interface BlueChildUnit {
  Cic: string;
  Uid: string;
  formattedUID: string;
  OwningSystem: string;
}

export interface RedChildUnit {
  Uid: string;
  formattedUID: string;
  OwningSystem: string;
}
// ..\Satair.Core\Models\Domain\UserLinkedAccounts.cs
export interface UserLinkedAccount {
  Admin: boolean;
  CurrentAccount: boolean;
  CompanyName: string;
  CompanyUid: string;
  ReadOnlyCustomer: boolean;
  BlueChildUnit?: BlueChildUnit;
  RedChildUnit?: RedChildUnit;
}

// ..\Satair.Core\Models\Domain\UserProfile.cs
export interface UserProfile {
  User: User;
  WorkAreas: string[];
  Company: B2BUnit;
  Timezones: string[];
}

// ..\Satair.Core\Models\Domain\UserRole.cs
export interface UserRole {
  Name: string;
}

// ..\Satair.Core\Models\Domain\UserSearch.cs
export interface UserSearch {
  Users: UserSearchPerson[];
  Pagination: Pagination;
  Count: number;
  Page: number;
  TotalPages: number;
}

// ..\Satair.Core\Models\Domain\UserSearchPerson.cs
export interface UserSearchPerson {
  Name: string;
  Uid: string;
  Active: boolean;
  Admin: boolean;
  ContactNumber: string;
  GlobalId: string;
  ReadOnly: boolean;
  FinanceCustomer: boolean;
  UserRoles: string[];
}

// ..\Satair.Core\Models\Domain\Vacancies.cs
export interface Vacancies {
  VacancyList: VacancyListItem[];
  Areas: string[];
}

// ..\Satair.Core\Models\Domain\Vacancy.cs
export interface Vacancy extends VacancyListItem {
  Description: string;
  ApplicationUrl: string;
  ApplicationDeadline: string;
  HomepageUrl: string;
  County: VacancyItem;
  Categories: VacancyCategories;
  Region: string;
  JobType: string;
  WorkingHours: string;
}

// ..\Satair.Core\Models\Domain\VacancyCategories.cs
export interface VacancyCategories {
  Item: VacancyItem[];
}

// ..\Satair.Core\Models\Domain\VacancyItem.cs
export interface VacancyItem {
  Type: string;
  Id: string;
  Text: string;
}

// ..\Satair.Core\Models\Domain\VacancyListItem.cs
export interface VacancyListItem {
  Id: string;
  Title: string;
  Appetizer: string;
  Location: string;
  DepartmentId: string;
  EndDate: string;
  StartDate: string;
  ReferenceNumber: string;
  Area: string;
}

// ..\Satair.Core\Models\Site\AuthenticationPipelineModule.cs
export interface AuthenticationPipelineModule {
  IsAdmin: boolean;
  IsReadOnly: boolean;
  IsFirstTime: boolean;
  HasAcceptedTou: boolean;
  HasAcceptedReleaseNotes: boolean;
  GlobalId: string;
  RememberMe: boolean;
  BetaCustomer: boolean;
  IsFinance: boolean;
  ReadOnlyAccount: boolean;
  UserUnitType: string;
}

// ..\Satair.Core\Models\Site\AuthenticationPipelineModule.cs
export interface ValidateTokenPipelineModule {
  IsValid: boolean;
  Reason: string;
}

// ..\Satair.Core\Models\Site\AuthenticationPipelineModule.cs
export interface CartChangedPipelineModule {
  CartChanged: boolean;
  Reason: string;
}

// ..\Satair.Core\Models\Site\CustomActionPipelineModule.cs
export interface CustomActionPipelineModule {
  VueRedirectName: string;
  ModalData: any;
  ActionType: ActionTypes;
}

// ..\Satair.Core\Models\Site\CustomActionPipelineModule.cs
export enum ActionTypes {
  Login = 'Login',
  VueRedirect = 'VueRedirect',
  TriggerModal = 'TriggerModal',
}

// ..\Satair.Core\Models\Site\ErrorPipelineModule.cs
export interface ErrorPipelineModule {
  HasError: boolean;
  FatalError: boolean;
  ErrorDescription: string;
}

// ..\Satair.Core\Models\Site\Menu.cs
export interface MenuQuicklinkGroup {
  Header: string;
  Items: MenuQuicklinksItem[];
}

// ..\Satair.Core\Models\Site\Menu.cs
export interface MenuQuicklinksItem {
  Headline: string;
  Description: string;
  Link: string;
  Icon: string;
  LinkOpenNew: boolean;
  LinkExternal: boolean;
}

// ..\Satair.Core\Models\Site\Menu.cs
export interface MenuHelplinkGroup {
  Header: string;
  Items: MenuHelplinksItem[];
}

// ..\Satair.Core\Models\Site\Menu.cs
export interface MenuHelplinksItem {
  Headline: string;
  Link: string;
  LinkOpenNew: boolean;
  LinkExternal: boolean;
}

// ..\Satair.Core\Models\Site\NotificationPipelineModule.cs
export interface NotificationPipelineModule {
  Title: string;
  Description: string;
  Type: NotificationTypes;
  Timing?: number;
  NotificationAction: NotificationAction;
  CloseDrawer: boolean;
  Context: NotificationContext;
}

// ..\Satair.Core\Models\Site\NotificationPipelineModule.cs
export interface NotificationAction {
  Name: string;
  Url: string;
  StringOne: string;
  StringTwo: string;
  Modal: boolean;
}

// ..\Satair.Core\Models\Site\NotificationPipelineModule.cs
export enum NotificationTypes {
  Success = 'Success',
  Information = 'Information',
  Warning = 'Warning',
  Error = 'Error',
}

// ..\Satair.Core\Models\Site\NotificationPipelineModule.cs
export enum NotificationContext {
  Normal = 'Normal',
  PriorityMissing = 'PriorityMissing',
  UnavailableProduct = 'UnavailableProduct',
}

// ..\Satair.Core\Models\Site\ProfilingItem.cs
export interface ProfilingItem {
  Operation: string;
  Ms: number;
}

// ..\Satair.Core\Models\Site\TrackingModule.cs
export interface TrackingModule {
  Tracking: TrackingRoot;
}

// ..\Satair.Core\Models\Site\UploadContent.cs
export interface UploadContent {
  Content: string[];
  Name: string;
  ContentType: string;
  Stream: string;
}

export interface AirbusProductInterchangeableItem {
  cageCode: string;
  id: string;
  interchangeabilityCode: number;
  leaderPart: boolean;
  partNumber: string;
  sortIndex: number;
}

export interface AirbusInterchangeabilityInfo {
  // interchangeabilityType will get deprecated
  interchangeabilityType?: string;
  originalProduct: OriginalProduct;
}

// OriginalProduct should eventually be removed once
// we get the correct response types

export interface OriginalProduct {
  airbusMaterialGroup: {
    code: string;
    name: string;
  };
  airbusPeriodicalCheckInterval: string;
  airbusProprietary: boolean;
  airbusSparePartClass: {
    code: string;
    name: string;
  };
  alternativePartNumbers: number[];
  baseOptions: any[];
  certificateType: {
    code: string;
    name: string;
  };
  code: string;
  dimensionsUnit: {
    code: string;
    name: string;
    sapCode: string;
  };
  enginePart: boolean;
  flammable: boolean;
  grossWeight: number;
  hazmat?: {
    code: string;
    name: string;
  };
  height: number;
  id: string;
  isAirbusHazmat: boolean;
  isKit: boolean;
  isTool: boolean;
  length: 0;
  lifeTimeIndicator: string;
  manufacturerAid: string;
  manufacturerData: {
    cageCode: string;
    name: string;
  };
  materialNumberExternal: string;
  maxShelfLife: number;
  minimumOrderQuantity: number;
  name: string;
  netWeight: number;
  newSearchResultLogo: string;
  origin: string;
  owningSystem: string;
  productRule: {
    priceVisible: string;
    quotable: string;
    sellable: string;
    stockVisible: string;
  };
  quantityStep: number;
  salesOrgData: [
    {
      minDeliveryQuantity: number;
      minOrderQuantity: number;
      packageQuantity: number;
      salesOrganisation: number;
    },
    {
      minDeliveryQuantity: number;
      minOrderQuantity: number;
      packageQuantity: number;
      salesOrganisation: number;
    },
  ];
  salesUnit: {
    code: string;
    name: string;
    sapCode: string;
  };
  serialNumberRequired: boolean;
  url: string;
  volume: number;
  volumeUnit: {
    code: string;
    name: string;
    sapCode: string;
  };
  weightUnit: {
    code: string;
    name: string;
    sapCode: string;
  };
  width: number;
}

export interface CodeNamePair {
  code?: string;
  name?: string;
}

export interface AirbusHeavyMaintenanceFormRequest {
  additionalInformation: string;
  aircraftTail: string;
  aircraftTypeCode: string;
  checkTypeCode: string;
  email: string;
  fromDate: string;
  maintenanceStation: string;
  msn: string;
  msnPrefixCode: string;
  name: string;
  operator: string;
  phoneNumber: string;
  toDate: string;
  fax: string;
}

export interface ValidationError {
  message: string;
  reason: string;
  subject: string;
  subjectType: string;
  type: string;
}

export interface SendQuoteEmailToAirbusRequest {
  acReg?: string;
  certificateRequiredAfterRepair?: string;
  companyData?: string;
  customerEmail?: string;
  customerName?: string;
  flightHours?: string;
  fromDate?: string;
  locationInfo?: string;
  materialNumberExternal?: string;
  message?: string;
  preferredRepairStation?: string;
  priority?: string;
  procurementType?: string;
  productId?: string;
  purchaseOrderNumber?: string;
  quantity?: string;
  removalComponentTechnicalDocumentSheet?: {
    content?: string;
    mimeType?: string;
    name?: string;
  };
  removalDate?: string;
  removalReason?: string;
  serialNumber?: string;
  serviceNeeded?: string;
  shipToAddress?: string;
  toDate?: string;
}

export interface ServiceOrder {
  airCraftRegistrationNumber: string;
  cageCode: string;
  goodsReceiptDate: string;
  leaseDate: string;
  leaseDuration: string;
  orderDate: string;
  orderNumber: string;
  partNumber: string;
  plannedEndDate: string;
  productName: string;
  purchaseOrderNumber: string;
  quoteApprovedDate: string;
  remarks: string;
  returnedDate: string;
  serialNumber: string;
  shipOrScrapDate: string;
  shippingDetails: string[];
  status: string;
}

export interface ServiceOrderRequestParams {
  airCraftRegistrationNumber?: string;
  cageCode?: string;
  fromDate?: string;
  orderNumber?: string;
  partNumber?: string;
  purchaseOrderNumber?: string;
  status?: string;
  toDate?: string;
}

export interface CartAvailabilityInfo {
  availabilityDate: string;
  infoStatus: string;
  quantity: number;
  warehouse: {
    code: string;
    name: string;
  };
}

export interface CartEntryUnit {
  code: string;
  infoStatus: string;
  name: string;
  sapCode: string;
}

export interface CartEntryPricing {
  availabilityInfos: CartAvailabilityInfo[];
  basePrice: BasePrice;
  entryNumber: number;
  errorMessage: string;
  owningSystem: string;
  priceAvailable: boolean;
  priceUnit: CartEntryUnit;
  priceUnitFactor: number;
  quantity: number;
  salesUnit: CartEntryUnit;
  totalPrice: BasePrice;
  zproPriceAvailable: boolean;
}

export interface MastershopInfo {
  masterShopId: string;
  price: BasePrice;
}

export interface CartPricing {
  code: string;
  entries: CartEntryPricing[];
  errorMesage: string;
  masterShopInfos: MastershopInfo[];
  productDiscounts: BasePrice;
  subTotal: BasePrice;
  totalDiscounts: BasePrice;
  totalPrice: BasePrice;
  totalPriceWithTax: BasePrice;
  totalTax: BasePrice;
}

export interface InitialProvisioningDocument {
  fileName: string;
  fileExtension: string;
  fileSize: string;
  category: CodeNamePair;
  code: string;
  modifiedDate: string;
}
